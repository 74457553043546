<template>
  <section class="category-block">
    <div class="category-title-block pb-4">
      <div class="d-flex align-center">
        <CategoryIcon class="me-2" :category="categoryName" />
        <div class="category-title-text">
          {{ categoryNameLocalized }}
          <!-- <span class="category-title-level">{{ levelLabel }}</span> -->
        </div>
      </div>
      <NuxtLink v-show="displayAllLessButton" :to="toCategoryLink">
        {{ categoryNameLocalized }} ({{ booksCounter }})
      </NuxtLink>
    </div>
    <div v-if="showCategoryInfo" class="category-info-block-wrapper pb-4">
      <template v-if="!isNarrow">
        <LibraryCategoryInfo
          :category="categoryName"
          class="library-category-info"
        />
        <LibraryCategoryAuthorInfo
          :category="categoryName"
          :card-color="'#FFFFFF'"
          :max-number="5"
          class="library-category-info"
        />
        <LibraryCategoryQuote
          :category="categoryName"
          class="library-category-info"
        />
      </template>

      <SlideGroup
        v-else
        :list="categoryInfoCards"
        :lazy-load-items="3"
        :show-arrows="false"
        wheel
      >
        <template #item="{ item }" class=" mr-4">
          <component
            :is="item.component"
            :category="categoryName"
            :card-color="
              item.component === 'LibraryCategoryAuthorInfo'
                ? '#FFFFFF'
                : undefined
            "
            :max-number="
              item.component === 'LibraryCategoryAuthorInfo' ? 5 : undefined
            "
            class="library-category-info"
          />
        </template>
      </SlideGroup>
    </div>

    <SlideGroup
      class="publication-wrapper pb-9"
      :list="publicationsByCategoryState"
      :lazy-load-items="booksInRow"
      :show-arrows="showArrows"
    >
      <template #item="{ item }">
        <LibraryItem
          v-if="item.id"
          class="category-item"
          :show-empty-row="needAddRow"
          :publication="item"
        />
        <DummyLibraryItem v-else class="category-item" :publication="item" />
      </template>
    </SlideGroup>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';

import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';

import LayoutManager from '@/services/utils/LayoutManager';
import publicationUtils from '@/services/utils/publicationUtils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

import LibraryItem from '@/components/views/LibraryItem/LibraryItem.vue';
import SlideGroup from '@/components/views/SlideGroup/SlideGroup.vue';
import DummyLibraryItem from '@/components/views/DummyLibraryItem/DummyLibraryItem';

import LibraryCategoryInfo from '@/components/views/CategoryView/LibraryCategoryInfo/LibraryCategoryInfo.vue';
import LibraryCategoryAuthorInfo from '@/components/views/CategoryView/LibraryCategoryAuthorInfo/LibraryCategoryAuthorInfo.vue';
import LibraryCategoryQuote from '@/components/views/CategoryView/LibraryCategoryQuote/LibraryCategoryQuote.vue';

import CategoryIcon from '@/components/controls/CategoryIcon/CategoryIcon.vue';

export default {
  name: 'Category',
  components: {
    LibraryCategoryInfo,
    LibraryCategoryAuthorInfo,
    LibraryCategoryQuote,
    SlideGroup,
    LibraryItem,
    CategoryIcon,
    DummyLibraryItem
  },
  props: {
    showCategoryInfo: Boolean,
    levelLabel: {
      type: String
    },
    categoryName: {
      type: String,
      required: true
    },
    publications: {
      type: Array,
      required: true
    },
    customCounter: [Number, String]
  },
  data() {
    return {
      isMounted: false,
      booksFit: 0,
      categoryInfoCards: [
        { component: 'LibraryCategoryInfo' },
        { component: 'LibraryCategoryAuthorInfo' },
        { component: 'LibraryCategoryQuote' }
      ]
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['brand']),
    ...mapGetters('MediaDetectorStore', ['mediaSize', 'additionalMediaSize']),
    isNarrow() {
      const { narrow } = this.mediaSize;
      return narrow;
    },
    needAddRow() {
      return (
        this.brand === BrandsEnum.OCEAN &&
        !!this.publications.find(p => p.type === PublicationsTypes.COLLECTION)
      );
    },
    showArrows() {
      return !this.isNarrow && this.publications.length >= this.booksFit;
    },
    toCategoryLink() {
      return this.$store.getters['LibraryStore/toCategoryLink'](
        this.publications[0]
      );
    },
    categoryNameLocalized() {
      const localizationKey = publicationUtils.getCategoryLocalizationKey(
        this.categoryName,
        this.$t.bind(this)
      );
      return localizationKey;
    },
    booksInRow() {
      const { narrow, normal } = this.mediaSize;
      const { libraryColumnTwo, libraryColumnFour } = this.additionalMediaSize;

      let booksInRow;
      switch (true) {
        case libraryColumnTwo:
          booksInRow = AppConstantsUtil.BOOKS_IN_ROW_TWO;
          break;
        case narrow:
          booksInRow = AppConstantsUtil.BOOKS_IN_ROW_NARROW;
          break;
        case libraryColumnFour:
          booksInRow = AppConstantsUtil.BOOKS_IN_ROW_FOUR;
          break;
        case normal:
          booksInRow = AppConstantsUtil.BOOKS_IN_ROW_NORMAL;
          break;
        default:
          booksInRow = AppConstantsUtil.BOOKS_IN_ROW_WIDE;
      }
      return booksInRow;
    },
    publicationsByCategoryState() {
      return this.$_dummyFiller(
        this.publications,
        this.booksFit,
        this.publications.length
      );
    },
    booksCounter() {
      const pubsLength = publicationUtils.countPublicationItems(
        this.publications
      );
      return this.customCounter || pubsLength;
    },
    displayAllLessButton() {
      return (
        this.booksCounter > this.getPublicationsAmountInCollapsedCategory()
      );
    }
  },
  mounted() {
    this.setDummyItems();
    LayoutManager.register({
      layout: debounce(this.setDummyItems.bind(this), 200),
      id: this._uid
    });
  },
  destroyed() {
    LayoutManager.unregister(this._uid);
  },
  methods: {
    setDummyItems() {
      const containerWidth = this.$el.offsetWidth;
      const sliderElementWidth = this.$el.querySelector('.slider-item')
        .offsetWidth;
      const categoryElementWidth = this.$el.querySelector('.category-item')
        .offsetWidth;
      const indent = sliderElementWidth - categoryElementWidth;
      const fullElementsFit = Math.floor(containerWidth / sliderElementWidth);
      const elWidth = sliderElementWidth - indent / fullElementsFit;
      this.booksFit = Math.floor(containerWidth / elWidth);
    },
    getPublicationsAmountInCollapsedCategory() {
      const currentMediaSizes = this.$store.getters[
        'MediaDetectorStore/mediaSize'
      ];
      const current = Object.keys(currentMediaSizes).find(
        key => currentMediaSizes[key]
      );
      const max = AppConstantsUtil.MAX_BOOKS_IN_LIBRARY_REDUCED_CATEGORY;
      const maxMobile =
        AppConstantsUtil.MAX_BOOKS_IN_LIBRARY_REDUCED_CATEGORY_MOBILE;
      const publicationsAmountByColumns = {
        narrow: maxMobile,
        normal: max,
        wide: max
      };
      return publicationsAmountByColumns[current] ?? max;
    },
    $_dummyFiller(publications, booksInRow) {
      const vacantPlaces = booksInRow - publications.length;
      if (vacantPlaces <= 0) {
        return publications;
      }

      const dummyPublications = Array.from({ length: vacantPlaces }, () => ({
        id: null,
        type: 'dummy'
      }));
      return publications.concat(dummyPublications);
    }
  }
};
</script>

<style src="./Category.less" lang="less"></style>
