<template>
  <div class="dummy-library-item" :class="[publication.type.toLowerCase()]">
    <div class="book-image-box">
      <v-img
        class="dummy-cover"
        :lazy-src="bookCoverPath"
        :src="bookCoverPath"
        :alt="alternativeText"
      />
    </div>
  </div>
</template>

<script>
import images from '@/assets/images';

export default {
  name: 'DummyLibraryItem',
  props: {
    publication: Object
  },
  data() {
    return {
      bookCoverPath: images.dummyCover
    };
  },
  computed: {
    alternativeText() {
      return `${this.publication.type}`;
    }
  }
};
</script>

<style lang="less">
.dummy-library-item {
  .book-image-box {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    cursor: default;
  }
}
</style>
