<template>
  <LibraryCategoryCard>
    <CategoryAuthorInfo v-bind="$attrs" />
  </LibraryCategoryCard>
</template>

<script>
import LibraryCategoryCard from '@/components/views/CategoryView/LibraryCategoryCard/LibraryCategoryCard.vue';
import CategoryAuthorInfo from '@/components/views/CategoryView/CategoryAuthorInfo/CategoryAuthorInfo.vue';

export default {
  name: 'LibraryCategoryAuthorInfo',
  components: { LibraryCategoryCard, CategoryAuthorInfo }
};
</script>

<style lang="less" src="./LibraryCategoryAuthorInfo.less"></style>
