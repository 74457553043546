<template>
  <CategoryQuoteCard v-if="currentQuote" :quote="currentQuote" />
</template>

<script>
import CategoryQuoteCard from '@/components/views/CategoryView/CategoryQuote/CategoryQuoteCard.vue';

export default {
  name: 'LibraryCategoryQuote',
  components: { CategoryQuoteCard },
  props: {
    category: {
      type: String,
      required: false
    }
  },
  computed: {
    categoryQuotes() {
      const categoryInfo = this.$store.getters['LibraryStore/getCategoryInfo'](
        this.category
      );
      return categoryInfo?.quotes || [];
    },
    currentQuote() {
      const index = Math.round(
        Math.random() * (this.categoryQuotes.length - 1)
      );
      return this.categoryQuotes[index] || null;
    }
  }
};
</script>

<style lang="less" src="./LibraryCategoryQuote.less"></style>
