<template>
  <LibraryCategoryCard>
    <div
      class="library-category-content d-flex flex-row flex-md-column flex-lg-row"
    >
      <CategoryInfoImage
        :category="category"
        class="library-category-info-image"
      />
      <div class="publication-category-description">
        {{ categoryDescription }}
      </div>
    </div>
  </LibraryCategoryCard>
</template>

<script>
import CategoryInfoImage from '@/components/views/CategoryView/CategoryInfo/CategoryInfoImage.vue';
import LibraryCategoryCard from '@/components/views/CategoryView/LibraryCategoryCard/LibraryCategoryCard.vue';

export default {
  name: 'LibraryCategoryInfo',
  components: { LibraryCategoryCard, CategoryInfoImage },
  props: {
    category: {
      type: String,
      required: false
    }
  },
  data() {
    return {};
  },
  computed: {
    categoryDescription() {
      const categoryInfo = this.$store.getters['LibraryStore/getCategoryInfo'](
        this.category
      );
      return categoryInfo?.description || '';
    }
  },
  methods: {}
};
</script>

<style lang="less" src="./LibraryCategoryInfo.less"></style>
